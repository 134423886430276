import React, { useState, useRef, useLayoutEffect, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  InputSelect,
  SearchBox,
  Button,
  DataTable,
  Pagination,
} from "@bluesilodev/timhutcomponents";
// import InputFile from "components/InputFile";
import { useQueryClient } from "@tanstack/react-query";

import { useGetAllUser } from "service/userAPI";
import { useGetAllLocation } from "service/locationAPI";

import { FiList, FiGrid } from "components/icons";
import { UserAddIcon } from "components/icons";

import DateDisplay from "./DateDisplay";
import CardGrid from "./CardGrid";
import TableMenu from "./TableMenu";
import { adminAndSuperVisorRole } from "utils/roleFromLocalStorage";

function EmployeePage() {
  const navigate = useNavigate();
  const [styleGrid, setStyleGrid] = useState(false);
  const [styleFlex, setStyleFlex] = useState(true);
  const [paginate, setPaginate] = useState({
    totalData: 0,
    rowsPerPage: 10,
    currentPage: 1,
  });

  const queryClient = useQueryClient();
  const [keyword, setKeyword] = useState("");
  const [location, setLocation] = useState("");
  const [department, setDepartment] = useState("");
  const [jobPosQuery, setJobPosQuery] = useState("");

  const defaultSelectedAllValue = "select an option";

  React.useEffect(() => {
    const resetIfDefault = (value, setter) => {
      if (value?.toLowerCase() === defaultSelectedAllValue) {
        setter(""); // Reset the value to an empty string
      }
    };

    resetIfDefault(location, setLocation);
    resetIfDefault(department, setDepartment);
    resetIfDefault(jobPosQuery, setJobPosQuery);
  }, [location, department, jobPosQuery]);

  // const { mutate: migrateUser, isLoading } = useMigrateUser({
  //   onSuccess: () => {
  //     console.log("File uploaded successfully!");
  //     refetch(); // Refetch the user data after success
  //   },
  //   onError: (error) => {
  //     console.log(`Error uploading file: ${error.message}`);
  //   },
  // });

  // const handleSubmitFile = (e) => {
  //   // Ensure the event is properly handled
  //   e.preventDefault(); // If it's a form submission event

  //   console.log("File input changed"); // Logs whenever a file is selected
  //   const selectedFile = e.target.files[0]; // Gets the first file in the input

  //   if (selectedFile) {
  //     const formData = new FormData();
  //     formData.append("csvFile", selectedFile); // Appends file to FormData

  //     console.log("Form data prepared:", formData); // Logs FormData for debugging

  //     // Call the mutation function
  //     migrateUser(formData);
  //   } else {
  //     console.log("No file selected"); // Logs if no file is selected
  //   }
  // };

  const handlePage = () => {
    navigate("/addEmployee");
  };

  const { data, isSuccess, refetch } = useGetAllUser(
    {
      keyword: keyword,
      location: location,
      department: department,
      jobPosition: jobPosQuery,
      rowsPerPage: paginate.rowsPerPage,
      currentPage: paginate.currentPage,
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("getUser");
        queryClient.invalidateQueries("userInform");
      },
      onError: (err) => {
        console.log("err: ", err);
      },
    }
  );

  if (isSuccess) {
    console.log("user data", data);
  }

  React.useEffect(() => {
    data &&
      setPaginate((oldData) => ({ ...oldData, totalData: data?.totalDocs }));
  }, [data]);

  const inputRef = useRef(null);

  // FETCH DATA IF SUCCES GET API
  if (isSuccess && !data) {
    refetch();
  }

  // CHANGE FOR FOCUS INPUT
  useLayoutEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [keyword]);

  // API SELECT LOCATION
  const { data: dataLocation, isPending: pendingLocation } = useGetAllLocation({
    onSuccess: () => {
      queryClient.invalidateQueries("getLocation");
    },
    onError: (err) => {
      console.log("Err", err);
    },
  });

  if (pendingLocation) {
    return <div>Loading...</div>;
  }

  const handleStyleGrid = () => {
    setStyleGrid(true);
    setStyleFlex(false);
  };
  const handleStyleFlex = () => {
    setStyleGrid(false);
    setStyleFlex(true);
  };

  const dataColumn = [
    {
      id: "firstName",
      accessorFn: (row) => {
        return {
          firstName: row?.firstName || "",
          photo: row.photo || "",
        };
      },
      cell: (param) => {
        if (param) {
          const valueParam = param.getValue();

          return (
            <div className="flex gap-2 items-center">
              <img
                className="w-[30px] h-[30px] rounded-full"
                src={valueParam?.photo[0]?.link}
                alt="firstNameImg"
              />
              <h1>{valueParam.firstName}</h1>
            </div>
          );
        }
      },
      header: () => <span>Employee</span>,
      enableSorting: true,
    },
    {
      id: "employeeStatus",
      accessorFn: (row) => {
        return row?.employeeStatus && row?.employeeStatus?.employeeType;
      },
      header: () => <div>Employee status</div>,
      enableSorting: true,
    },
    {
      id: "location",
      accessorFn: (row) => {
        const data = row?.locations && row?.locations[0]?.locationName;
        return data;
      },
      header: () => <span>Location</span>,
      enableSorting: true,
    },
    {
      id: "department",
      accessorFn: (row) => {
        return (
          row?.userInformation &&
          row?.userInformation?.employeementDetail?.departments
        );
      },
      header: () => <span>Department</span>,
      enableSorting: true,
    },
    {
      id: "jobPosition",
      accessorFn: (row) => {
        return (
          row?.userInformation &&
          row?.userInformation?.employeementDetail?.jobPosition
        );
      },
      header: () => <span>Job Position</span>,
      enableSorting: true,
    },
    {
      id: "email",
      accessorFn: (row) => row?.email,
      header: () => <span>Email</span>,
      enableSorting: true,
    },
    {
      id: "dateJoin",
      accessorFn: (row) => {
        return (
          row?.userInformation &&
          row?.userInformation?.employeementDetail?.joinDate
        );
      },
      cell: (param) => {
        const val = param.getValue();
        return <DateDisplay dateStr={val} />;
      },
      header: () => <span>Date Joining</span>,
      enableSorting: true,
    },
    {
      id: "contactNumber",
      accessorFn: (row) => row?.phoneNumber,
      header: () => <span>Contact Number</span>,
      enableSorting: true,
    },
    {
      id: "action",
      disableSorting: true,
      header: () => "",
      accessorFn: (row) => {
        return row && row;
      },
      cell: (param) => {
        if (param) {
          const data = param.getValue();

          return (
            <div className="cursor-pointer w-fit">
              <TableMenu data={data} />
            </div>
          );
        }
      },
    },
  ];

  // MAPPING LOCATION
  const locationData =
    dataLocation &&
    [...new Set(dataLocation.data.map((item) => item.locationName))].map(
      (item, index) => {
        return {
          value: item,
          label: item,
        };
      }
    );

  // MAPPING DEPARTMENT
  const departmentData =
    dataLocation &&
    [
      ...new Set(
        dataLocation.data
          .filter((item) => (location ? item.locationName === location : true))
          .flatMap((entry) => entry.departments)
          .map((item) => item?.department)
      ),
    ].map((item, index) => {
      return {
        label: item,
        value: item,
      };
    });

  // MAPPING JOB POSITION
  // const jobPosition =
  //   dataLocation &&
  //   dataLocation.data.map((item, index) => {
  //     return {
  //       label: item.departments[0].jobPosition,
  //       value: item.departments[0].jobPosition,
  //     };
  //   });

  const jobPositionData =
    dataLocation &&
    dataLocation?.data
      .filter((item) => (location ? item.locationName === location : true))
      .flatMap((depart) => {
        return depart.departments
          .filter((item) =>
            department ? item.department === department : true
          )
          .flatMap((partIdx) => {
            return partIdx.jobPosition.map((item, index) => {
              return {
                label: item,
                value: item,
              };
            });
          });
      });
  const jobPosition = [];
  jobPositionData?.forEach((item) => {
    console.log("testtest", item, jobPosition);
    if (!jobPosition.some((single) => single.label === item.label)) {
      jobPosition.push({
        label: item.label,
        value: item.value,
      });
    }
  });

  return (
    <div>
      {/* TABLE DATA TABLE */}

      <div className="w-full pt-4">
        <div className="w-full flex justify-between">
          <div className={`flex w-full gap-4`}>
            <InputSelect
              onChange={(e) => setLocation(e.target.value)}
              title={"Locations"}
              options={locationData}
              classname={"w-full"}
            />
            <InputSelect
              onChange={(e) => setDepartment(e.target.value)}
              title={"Department"}
              options={departmentData}
              classname={"w-full"}
            />

            <InputSelect
              onChange={(e) => setJobPosQuery(e.target.value)}
              title={"Job Positions"}
              options={jobPosition}
              classname={"w-full"}
            />
            <div className="w-[150px] border-[1px] border-black flex gap-1 rounded-md p-1">
              <button
                type="button"
                className="w-[80px] group"
                onClick={handleStyleGrid}
              >
                <div
                  className={`group-hover:bg-orange-500 group-hover:text-white group-hover:duration-300 group-hover:transition-all h-[50px] w-full rounded-md flex justify-center items-center ${
                    styleGrid ? "bg-orange-500" : "bg-white"
                  }`}
                >
                  <FiGrid
                    className={"group-hover:text-white w-[20px] h-[20px]"}
                    color={`${styleGrid ? "white" : "black"}`}
                  />
                </div>
              </button>

              <button
                type="button"
                className="w-[80px] group"
                onClick={handleStyleFlex}
              >
                <div
                  className={`group-hover:bg-orange-500 group-hover:text-white h-[50px] w-full rounded-md flex justify-center items-center group-hover:duration-300 group-hover:transition-all ${
                    styleFlex ? "bg-orange-500" : "bg-white"
                  }`}
                >
                  <FiList
                    className={
                      "group-hover:text-white w-[20px] h-[20px] group-hover:duration-300 group-hover:transition"
                    }
                    color={`${styleFlex ? "white" : "black"}`}
                  />
                </div>
              </button>
            </div>
            <SearchBox
              className={"w-full"}
              value={keyword}
              ref={inputRef}
              onChange={(e) => setKeyword(e.target.value)}
              placeholder="Search by name"
            />

            {adminAndSuperVisorRole && (
              <Button
                onClick={handlePage}
                style="solid"
                className={" text-white h-full w-full"}
                label={
                  <div className="flex px-4 gap-1">
                    <UserAddIcon />
                    <div>Add Employee </div>
                  </div>
                }
              />
            )}

            {/* <InputFile onChange={handleSubmitFile} classname={"w-full"} /> */}
          </div>
        </div>
        {styleFlex && (
          <div className="pt-5">
            <DataTable
              title="Employee Data"
              columns={dataColumn}
              data={!data?.data ? [] : data?.data}
              pagination={false}
            />
            <div className="py-2" />
            <Pagination
              totalData={paginate.totalData}
              rowsPerPage={paginate.rowsPerPage}
              currentPage={paginate.currentPage}
              onChangeCurrentPage={(val) =>
                setPaginate((oldData) => ({ ...oldData, currentPage: val + 1 }))
              }
              onChangeRowsPerPage={(val) =>
                setPaginate((oldData) => ({ ...oldData, rowsPerPage: val }))
              }
            />
          </div>
        )}
        {styleGrid && (
          <div className="pt-5 flex gap-5 flex-wrap">
            {!data ? (
              <h1>Data Not Found!</h1>
            ) : (
              data?.data.map((item, index) => {
                return (
                  <div key={index} className="">
                    <CardGrid item={item} />
                  </div>
                );
              })
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default EmployeePage;
